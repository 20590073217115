@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

body {
    background-color: #fafafa;
}

.group:hover .group-hover\:block {
    display: block;
}
.header-img > img {
    width: 60%;
    cursor: pointer;
}
.bg-blue-500 {
    background-color: #1c709f;
}
.bg-color {
    background-color: #fafafa;
}
.leyout-header {
    height: 65px !important;
}
.send-btn {
    padding: 4px;
    background-color: #1c709f;
    /* background-color: #b49561; */
    border-radius: 0.8rem !important;
}
.mic-icon {
    width: 30px;
    height: 30px;
}
input {
    background-color: #f2f3f4 !important;
}
.home {
    position: relative;
    background-color: #56737d;
}
.home-txt {
    text-align: center;
    font-size: 5rem;
    color: #fafafa;
    margin-top: 10rem;
    font-weight: bold;
    text-shadow: rgba(0, 20, 30, 0.33) 0px 4px 4px;
}
.home-btn-icon {
    width: 30px;
}
.home-page-btn {
    cursor: pointer;
    background-color: #fafafa;
    width: 35%;
    padding: 1rem;
}
.lagacy-short-icon {
    width: 23px;
    height: 23px;
}
.chat-legacy-text {
    color: #4b5960;
}
.chat-input-shadow {
    padding-right: 100px !important;
    background-color: #ffffff !important;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.361312px 0.361312px -0.833333px,
        rgba(0, 0, 0, 0.25) 0px 1.37312px 1.37312px -1.66667px,
        rgba(0, 0, 0, 0.25) 0px 6px 6px -2.5px;
}
.home-shadow {
    box-shadow: rgba(0, 20, 30, 0.33) 0px 4px 4px -2px;
}
.home-content {
    position: relative;
}
.home video {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
}

.disabled-widget {
    background-color: #f2f3f4 !important;
}
.bg-color-white {
    background: #ffffff !important;
}

@keyframes l3 {
    20% {
        background-position: 0% 0%, 50% 50%, 100% 50%;
    }
    40% {
        background-position: 0% 100%, 50% 0%, 100% 50%;
    }
    60% {
        background-position: 0% 50%, 50% 100%, 100% 0%;
    }
    80% {
        background-position: 0% 50%, 50% 50%, 100% 100%;
    }
}

.chat-boat-popup {
    position: absolute;
    box-sizing: border-box !important;
    bottom: 120px !important;
    right: 40px !important;
    background-color: #ffffff;
    height: 600px;
    width: 450px;
    border-radius: 30px !important;
    box-shadow: 0px 0px 10px black;
}

.chat-boat-floating-icon-cointainer {
    bottom: 40px !important;
    right: 40px !important;
    margin: 0px !important;
}

.chat-boat-floating-icon > i {
    font-size: 26px !important;
}

.chat-bot-main-container {
    box-sizing: border-box;
    /* min-height: 100vh; */
    width: auto;
    place-content: center flex-start;
    align-items: center;
    background-color: #fafafa;
    display: flex;
    flex-flow: column;
    gap: 0px;
    height: min-content;
    overflow: visible;
    padding: 0px;
    position: relative;
}
