.user-response {
    place-content: center flex-end;
    align-items: center;
    display: flex;
    flex: 0 0 auto;
    flex-flow: row;
    gap: 8px;
    height: min-content;
    max-width: 1116px;
    overflow: visible;
    padding: 0px;
    position: relative;
    width: 100%;
    box-sizing: border-box;
}

.user-response-outer-div {
    box-sizing: border-box;

    transform: none;
    transform-origin: 50% 50% 0px;

    order: 0;

    place-content: center flex-end;
    align-items: center;
    display: flex;
    flex: 0 0 auto;
    flex-flow: row;
    gap: 8px;
    height: min-content;
    max-width: 1116px;
    overflow: visible;
    padding: 0px;
    position: relative;
    width: 100%;
}

.user-response-inner-div {
    box-sizing: border-box;

    transform: none;
    transform-origin: 50% 50% 0px;

    flex: 0 0 auto;
    height: auto;
    max-width: 100%;
    position: relative;
    width: auto;

    background-color: rgb(0, 102, 153);
    max-width: 100%;
    transform: none;
    transform-origin: 50% 50% 0px;
    border-radius: 10px;
    box-shadow: rgba(255, 255, 255, 0.5) 0px 0.5px 0px 0px inset,
        rgba(0, 0, 0, 0) 0px 0.602187px 0.602187px -1.25px,
        rgba(0, 0, 0, 0) 0px 2.28853px 2.28853px -2.5px,
        rgba(0, 0, 0, 0) 0px 10px 10px -3.75px;

    place-content: center;
    align-items: center;
    display: flex;
    flex-flow: row;
    gap: 0px;
    height: min-content;
    min-height: 56px;
    overflow: hidden;
    padding: 10px 20px;
    position: relative;

    font-size: 12px;
}

.user-response-div {
    box-sizing: border-box;

    outline: none;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    /* flex-shrink: 0; */
    text-shadow: rgba(0, 0, 0, 0.25) 0px 0.5px 0.5px;
    transform: none;
    transform-origin: 50% 50% 0px;

    flex: 0 0 auto;
    height: auto;
    max-width: 360px;
    overflow: hidden;
    position: relative;
    white-space: pre-wrap;
    width: 100%;
    word-break: break-word;
    overflow-wrap: break-word;
}

.user-response-p {
    box-sizing: border-box;

    font-family: "Open Sans", "Open Sans Placeholder", sans-serif;
    font-weight: 600;
    line-height: 1.5em;

    font-style: normal;
    font-size: 16px;
    letter-spacing: 0;
    text-transform: none;
    text-decoration: none;
    line-height: 1.5em;
    text-align: start;

    margin: 0px;
    padding: 0px;
}
