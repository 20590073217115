.bot-response-outer-div {
    flex: 0 0 auto;
    height: auto;
    min-width: 240px;
    position: relative;
    width: 100%;
    box-sizing: border-box;
}

.bot-response-inner-div {
    place-content: flex-start;
    align-items: flex-start;
    display: flex;
    flex-flow: row;
    gap: 16px;
    height: min-content;
    overflow: visible;
    padding: 0px;
    position: relative;
    width: 100%;
    transform: none;
    transform-origin: 50% 50% 0px;
}

.bot-response-logo-outer-div {
    opacity: 1;
    transform: none;
    transform-origin: 50% 50% 0px;
    place-content: center flex-start;
    align-items: center;
    display: flex;
    flex: 0 0 auto;
    flex-flow: row;
    gap: 8px;
    height: min-content;
    overflow: hidden;
    padding: 0px;
    position: relative;
    width: min-content;
    box-sizing: border-box;
}

.bot-response-logo-inner-div {
    box-sizing: border-box;
    flex: 0 0 auto;
    height: 32px;
    position: relative;
    width: 32px;
    transform: none;
    transform-origin: 50% 50% 0px;
}

.bot-response-logo-div {
    box-sizing: border-box;
    position: absolute;
    border-radius: inherit;
    inset: 0px;
}

.bot-response-logo-image {
    box-sizing: border-box;
    display: block;
    width: 100%;
    height: 100%;
    border-radius: inherit;
    object-position: center center;
    object-fit: cover;
    image-rendering: auto;
}

.bot-response-content-div {
    border-bottom-width: 1px;
    border-color: rgb(242, 243, 244);
    border-left-width: 1px;
    border-right-width: 1px;
    border-style: solid;
    border-top-width: 1px;
    font-family: "PT Serif";
    box-sizing: border-box;
    place-content: center flex-start;
    align-items: flex-start;
    display: flex;
    flex: 1 0 0px;
    flex-flow: column;
    gap: 24px;
    height: min-content;
    overflow: hidden;
    padding: 24px;
    position: relative;
    width: 1px;
    background-color: rgb(255, 255, 255);
    opacity: 1;
    transform: none;
    border-radius: 10px;
    box-shadow: rgba(0, 20, 30, 0.33) 0px 4px 4px -2px;
    transform-origin: 50% 50% 0px;
}

.loader-div {
    width: 4rem;
    height: 28px;
}

.loader {
    width: 100%;
    background: #ffffff;
    aspect-ratio: 2;
    --_g: no-repeat radial-gradient(circle closest-side, #000 90%, #0000);
    background: var(--_g) 0% 50%, var(--_g) 50% 50%, var(--_g) 100% 50%;
    background-size: calc(100% / 3) 50%;
    animation: l3 1s infinite linear;
}

.bot-response-buttons {
    box-sizing: border-box;

    place-content: flex-end;
    align-items: flex-end;
    display: flex;
    flex: 0 0 auto;
    flex-flow: row;
    gap: 8px;
    height: min-content;
    max-width: 1116px;
    overflow: visible;
    padding: 0px;
    position: relative;
    width: 100%;

    opacity: 1;
    transform: none;
    transform-origin: 50% 50% 0px;
    flex-wrap: wrap;
}
