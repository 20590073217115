@media only screen and (max-width: 809px) {
    .chat-body {
        padding: 24px;
    }
}
@media only screen and (min-width: 810px) {
    .chat-body {
        padding: 24px 64px 46px;
    }
}

.chat-body {
    max-width: 100%;
    width: 100%;
    /* padding: 24px 64px 46px !important; */
    place-content: center flex-start;
    align-items: center;
    display: flex;
    flex-flow: column;
    gap: 24px;
    height: min-content;
    overflow: auto;
    position: relative;
    /* min-height: 80vh; */
}

.chat-body-inner-div {
    position: relative;
    max-width: 1200px;
    overflow: auto;
    width: 100%;
    padding-bottom: 200px;
    place-content: flex-end flex-start;
    align-items: flex-end;
    display: flex;
    flex-flow: column;
    gap: 24px;
    height: min-content;
    overflow: auto;
    position: relative;
}
