.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.layout {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.layout-container {
    font-size: 12px;
    font-family: sans-serif;
    box-sizing: border-box;
    place-content: center flex-start;
    align-items: center;
    background-color: #fafafa;
    display: flex;
    flex-flow: column;
    gap: 0px;
    height: min-content;
    overflow: visible;
    padding: 0px;
    position: relative;
    min-height: 100vh;
    width: auto;
}
