.bot-message-outer-div {
    box-sizing: border-box;

    place-content: center;
    align-items: center;
    display: flex;
    flex: 0 0 auto;
    flex-flow: column;
    gap: 10px;
    height: min-content;
    overflow: visible;
    padding: 0px;
    position: relative;
    width: 100%;

    transform: none;
    transform-origin: 50% 50% 0px;
}

.bot-message-inner-div {
    box-sizing: border-box;

    flex: 0 0 auto;
    height: auto;
    position: relative;
    white-space: pre-wrap;
    width: 100%;
    word-break: break-word;
    overflow-wrap: break-word;

    outline: none;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-shrink: 0;
    color: rgb(0, 153, 255);
    /* text-decoration: underline; */
    transform: none;
    transform-origin: 50% 50% 0px;
}

.bot-message-p {
    box-sizing: border-box;

    margin: 0px;
    padding: 0px;

    font-family: sans-serif;
    font-style: normal;
    font-weight: 400;
    color: #000;
    letter-spacing: 0;
    text-transform: none;
    text-decoration: none;
    text-align: start;

    font-family: "PT Serif", "PT Serif Placeholder", serif;
    font-size: 20px;
    line-height: 1.5em;
    text-align: left;
    color: rgb(0, 20, 30);
}
