.bot-response-button {
    box-sizing: border-box;
    padding: 15px 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    line-height: 1.2em;
    border-radius: 10px;
    font-family: sans-serif;
    font-style: normal;
    font-weight: 600;
}

.bot-response-clicked {
    border: 1px solid rgb(0, 20, 30);
    color: #fff;
    background: rgb(0, 20, 30);
}

.bot-response-disable {
    border: 1px solid rgb(64, 79, 87);
    color: rgb(64, 79, 87);
    background: #ffffff;
    cursor: not-allowed !important;
    opacity: 0.5;
}

.bot-response-enable {
    border: 1px solid rgba(64, 79, 87, 1);
    text-shadow: rgba(0, 0, 0, 0) 0px 0.5px 0.5px;
    color: rgb(64, 79, 87);
    background: linear-gradient(
        rgba(255, 255, 255, 0.1) 0%,
        rgba(255, 255, 255, 0) 100%
    );
}

.bot-response-enable:hover {
    background-color: rgb(242, 243, 244);
    border: 2px solid rgb(0, 20, 30);
    color: rgb(0, 20, 30);

    transition: background-color 1, border 1s ease-in-out, color 1s;
}
