.chat-footer-container-div {
    box-sizing: border-box;

    bottom: 0px;
    flex: 0 0 auto;
    height: auto;
    max-width: 1200px;
    position: sticky;
    width: 100%;
    z-index: 10;
    will-change: transform;
}

.chat-footer-inner-div {
    box-sizing: border-box;

    background: linear-gradient(
        rgba(250, 250, 250, 0) 0%,
        rgb(250, 250, 250) 11%
    );
    max-width: 100%;
    width: 100%;
    opacity: 1;

    place-content: center;
    align-items: center;
    display: flex;
    flex-flow: column;
    gap: 24px;
    padding: 32px 16px;
    height: min-content;
    overflow: visible;
    position: relative;
}

.chat-footer-input-outer-div {
    box-sizing: border-box;

    border-bottom-width: 1px;
    border-color: rgba(0, 20, 30, 0.1);
    border-left-width: 1px;
    border-right-width: 1px;
    border-style: solid;
    border-top-width: 1px;
    background-color: rgb(255, 255, 255);
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.361312px 0.361312px -0.833333px,
        rgba(0, 0, 0, 0.25) 0px 1.37312px 1.37312px -1.66667px,
        rgba(0, 0, 0, 0.25) 0px 6px 6px -2.5px;
    opacity: 1;

    place-content: center;
    align-items: center;
    display: flex;
    flex: 0 0 auto;
    flex-flow: row;
    gap: 8px;
    height: 64px;
    overflow: visible;
    padding: 4px 8px;
    position: relative;
    width: 100%;
    z-index: 2;
}

.chat-footer-input-inner-div {
    box-sizing: border-box;

    place-content: center;
    align-items: center;
    display: flex;
    flex: 1 0 0px;
    flex-flow: row;
    gap: 0px;
    height: 56px;
    overflow: visible;
    padding: 0px;
    position: relative;
    width: 1000px;
    opacity: 1;
}

.chat-footer-input-div {
    box-sizing: border-box;
    flex: 1 0 0px;
    height: 100%;
    position: relative;

    opacity: 1;
}

.chat-footer-input {
    box-sizing: border-box;

    border-width: 2px;
    border-color: rgba(0, 102, 153, 0);
    pointer-events: auto;
    border: none;
    width: 100%;
    box-sizing: border-box;
    outline: none;
    resize: none;
    margin: 0px;
    font-family: "open sans Semi-bold", "open sans", Inter, system-ui,
        -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial,
        sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    appearance: none;
    color: rgb(0, 20, 30);
    background-color: rgba(255, 255, 255, 0) !important;
    border-radius: 19px;
    text-align: left;
    line-height: 1.2;
    caret-color: rgb(0, 20, 30);
    display: flex;
    height: 100%;
    padding: 16px;
    direction: ltr;
    text-overflow: unset;
    box-shadow: rgba(204, 208, 210, 0) 0px 0px 0px 2px inset;
    font-size: 18px;
    font-weight: 600;
}

::placeholder {
    color: rgb(127, 137, 142);
    opacity: 1; /* Firefox */
}

.send-button {
    box-sizing: border-box;

    /* background-color: rgb(0, 102, 153); */
    border-radius: 20px;
    opacity: 1;

    place-content: center;
    align-items: center;
    display: flex;
    flex: 0 0 auto;
    flex-flow: row;
    gap: 0px;
    height: 48px;
    padding: 10px 20px;
    position: relative;
    width: 48px;
}

.send-button-div {
    box-sizing: border-box;

    place-content: center flex-start;
    align-items: center;
    display: flex;
    flex: 0 0 auto;
    flex-flow: row;
    gap: 4px;
    height: min-content;
    overflow: visible;
    padding: 0px;
    position: relative;
    width: min-content;
    opacity: 1;

    flex: 0 0 auto;
    height: 24px;
    position: relative;
    width: 24px;
}

.navigate-outer-div {
    box-sizing: border-box;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-flow: row;
    gap: 10px;
    height: min-content;
    overflow: hidden;
    padding: 0px;
    position: relative;
    width: 100%;

    opacity: 1;
}

.navigate-inner-div {
    box-sizing: border-box;
    width: 50%;

    height: auto;
    position: relative;
    white-space: pre;

    outline: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    transform: none;
    opacity: 1;
    width: 100%;
}

.navigate-p {
    box-sizing: border-box;
}

.navigate-a {
    box-sizing: border-box;

    color: #006699;
    text-decoration: underline;

    font-family: sans-serif;
    font-weight: 400;
    font-size: 16px;
    cursor: pointer;
}

.navigate-a:hover {
    color: #b49561;
    text-decoration: underline;
}

.start-over-inner-div {
    box-sizing: border-box;
    width: 50%;

    height: auto;
    position: relative;
    white-space: pre;

    outline: none;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    transform: none;
    opacity: 1;
}

.start-over-p {
    box-sizing: border-box;
}

.start-over-a {
    box-sizing: border-box;

    color: #006699;
    text-decoration: underline;

    font-family: sans-serif;
    font-weight: 400;
    font-size: 16px;
    cursor: pointer;
}

.start-over-a:hover {
    color: #b49561;
    text-decoration: underline;
}
