/* .header {
    font-size: 12px;
    font-family: sans-serif;
    box-sizing: border-box;
    background-color: rgb(255, 255, 255);
    flex: 0 0 auto;
    height: 80px;
    overflow: visible;
    position: relative;
    width: 100%;
    z-index: 10;
}

.header-outer-div {
    font-size: 12px;
    font-family: sans-serif;
    box-sizing: border-box;
    place-content: center;
    align-items: center;
    display: flex;
    flex: 0 0 auto;
    flex-flow: column;
    gap: 10px;
    height: min-content;
    left: 0px;
    min-height: 216px;
    overflow: visible;
    padding: 0px;
    position: absolute;
    top: 0px;
    right: unset;
    width: 100%;
}

.header-inner-div {
    font-size: 12px;
    font-family: sans-serif;
    box-sizing: border-box;
    place-content: center space-between;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.18) 0px 0.602187px 0.602187px -1.25px,
        rgba(0, 0, 0, 0.16) 0px 2.28853px 2.28853px -2.5px,
        rgba(0, 0, 0, 0.063) 0px 10px 10px -3.75px;
    display: flex;
    flex: 0 0 auto;
    flex-flow: row;
    height: 78px;
    left: 0px;
    overflow: visible;
    padding: 0px 32px;
    position: absolute;
    right: 0px;
    top: 0px;
    z-index: 1;
}

.header-outer-logo-div {
    font-size: 12px;
    font-family: sans-serif;
    box-sizing: border-box;
    place-content: center flex-start;
    align-items: center;
    display: flex;
    flex: 0 0 auto;
    flex-flow: row;
    gap: 24px;
    height: min-content;
    overflow: visible;
    padding: 0px;
    position: relative;
    width: min-content;
}

.header-inner-logo-div {
    font-size: 12px;
    font-family: sans-serif;
    box-sizing: border-box;
    place-content: center flex-start;
    align-items: center;
    display: flex;
    flex: 0 0 auto;
    flex-flow: row;
    gap: 10px;
    height: 48px;
    overflow: visible;
    padding: 0px;
    position: relative;
    width: 117px;
}

.header-logo-div {
    font-size: 12px;
    font-family: sans-serif;
    box-sizing: border-box;
    position: absolute;
    border-radius: inherit;
    inset: 0px;
}

.header-logo-img {
    font-size: 12px;
    font-family: sans-serif;
    box-sizing: border-box;
    display: block;
    width: 100%;
    height: 100%;
    border-radius: inherit;
    object-position: center center;
    object-fit: contain;
    image-rendering: auto;
} */

.header-outer-div {
    position: relative;
    top: 0px;
    z-index: 10;
    box-shadow: rgba(48, 49, 51, 0.15) 0px 8px 8px 0px;
    box-sizing: border-box;
    width: 100%;
}

.header {
    background-color: rgb(255, 255, 255);
    align-items: center;
    justify-content: space-between;
    display: flex;
    position: relative;
    z-index: 1;
    box-sizing: border-box;
    height: 80px;
    padding-left: 32px;
    padding-right: 32px;
}

.header-left-div {
    cursor: pointer;
    -webkit-box-align: center;
    align-items: center;
    display: inline-flex;
    height: 42px;
    width: 42px;
    box-sizing: border-box;
}

.header-middle-a {
    box-sizing: border-box;
    text-decoration: none;
}

.header-middle-svg {
    vertical-align: middle;
}

.header-right-a {
    box-sizing: border-box;
    text-decoration: none;
}

.header-right-inner-button {
    border-radius: 30px;
    border: 1px solid rgb(127, 137, 142);
    background-color: rgb(204, 208, 210);
    color: rgb(127, 137, 142);
    -webkit-box-align: center;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    width: fit-content;
    display: flex;
    z-index: 1;
    box-shadow: none;
    padding: 10px;
    transition: 0.1s ease-in;
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    box-sizing: border-box;
    outline: transparent solid 1px;
}

.header-right-inner-div {
    margin-right: 0px;
    line-height: 8px;
    box-sizing: border-box;
}
