.chat-header-outer-div {
    box-sizing: border-box;
    place-content: center;
    align-items: center;
    background: linear-gradient(
        rgb(250, 250, 250) 88%,
        rgba(250, 250, 250, 0) 100%
    );
    display: flex;
    flex: 0 0 auto;
    flex-flow: row;
    gap: 10px;
    height: min-content;
    overflow: hidden;
    padding: 32px;
    position: relative;
    width: 100%;
    z-index: 9;
}

.chat-header-inner-div {
    box-sizing: border-box;
    outline: none;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-shrink: 0;
    transform: none;
    flex: 1 0 0px;
    height: auto;
    position: relative;
    white-space: pre-wrap;
    width: 1px;
    word-break: break-word;
    overflow-wrap: break-word;
    z-index: 1;
}

.chat-header-content {
    box-sizing: border-box;
    margin: 0px;
    padding: 0px;
    font-family: "PT Serif";
    font-size: 28px;
    line-height: 1.5em;
    text-align: center;
    /* color: rgba(0, 20, 30, 1); */
}
