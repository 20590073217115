.card-outer-div {
    box-sizing: border-box;

    flex: 0 0 auto;
    height: auto;
    position: relative;
    width: 100%;

    opacity: 1;
    transform: none;
    transform-origin: 50% 50% 0px;
}

@media only screen and (max-width: 800px) {
    .card-inner-div {
        display: flex;
        flex-direction: column-reverse;
        justify-content: flex-end;
        align-items: stretch;
    }

    .card-image-wapper-div {
        width: 100%;
        height: auto;
    }

    .card-heading-p {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
}

@media only screen and (min-width: 801px) {
    .card-inner-div {
        display: flex;
        place-content: flex-start;
        align-items: flex-start;
        flex-flow: row;
    }

    .card-image-wapper-div {
        width: auto;
        height: auto;
    }
}

.card-inner-div {
    box-sizing: border-box;

    cursor: pointer;
    gap: 16px;
    height: min-content;
    min-width: 120px;
    overflow: visible;
    padding: 16px;
    position: relative;

    border-bottom-width: 1px;
    border-color: rgba(0, 20, 30, 0.1);
    border-left-width: 1px;
    border-right-width: 1px;
    border-style: solid;
    border-top-width: 1px;
    background-color: rgb(255, 255, 255);
    width: 100%;
    border-radius: 10px;
    transform: none;
    transform-origin: 50% 50% 0px;
    opacity: 1;
}

.card-inner-div:hover {
    border-color: rgb(180, 149, 97);
    border-width: 2px;
    transition: border-width 2s;
}

.card-inner-div:hover
    .card-content-outer-div
    .card-content-inner-div
    .card-heading-div
    .card-heading-p {
    color: rgb(180, 149, 97);
}

.card-content-outer-div {
    box-sizing: border-box;

    place-content: flex-start center;
    align-items: flex-start;
    display: flex;
    flex: 1 0 0px;
    flex-flow: row;
    gap: 10px;
    height: min-content;
    overflow: visible;
    padding: 0px;
    position: relative;
    width: 100%;

    transform: none;
    transform-origin: 50% 50% 0px;
    opacity: 1;
}
.card-content-inner-div {
    box-sizing: border-box;

    place-content: flex-start center;
    align-items: flex-start;
    display: flex;
    flex: 1 0 0px;
    flex-flow: column;
    gap: 8px;
    height: min-content;
    overflow: visible;
    padding: 0px;
    position: relative;
    width: 1px;

    transform: none;
    transform-origin: 50% 50% 0px;
    opacity: 1;
}

.card-heading-div {
    box-sizing: border-box;

    flex: 0 0 auto;
    height: auto;
    position: relative;
    white-space: pre-wrap;
    width: 100%;
    word-break: break-word;
    overflow-wrap: break-word;

    outline: none;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-shrink: 0;
    transform: none;
    transform-origin: 50% 50% 0px;
    opacity: 1;
}
.card-heading-p {
    box-sizing: border-box;

    margin: 0px;
    padding: 0px;

    font-family: sans-serif;
    font-weight: 400;
    text-transform: none;
    text-decoration: none;
    line-height: 1.2em;
    text-align: start;

    font-family: "PT Serif";
    font-size: 28px;
    font-weight: 700;
    letter-spacing: 0px;
    text-align: left;
    color: rgb(0, 20, 30);
}

.card-content-div {
    box-sizing: border-box;

    flex: 0 0 auto;
    height: auto;
    position: relative;
    white-space: pre-wrap;
    width: 100%;
    word-break: break-word;
    overflow-wrap: break-word;

    outline: none;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-shrink: 0;
    transform: none;
    transform-origin: 50% 50% 0px;
    opacity: 1;
}
.card-content-p {
    box-sizing: border-box;

    margin: 0px;
    padding: 0px;

    font-family: sans-serif;
    font-weight: 400;
    color: #000;
    font-size: 16px;
    text-transform: none;
    text-decoration: none;
    line-height: 1.2em;
    text-align: start;

    font-family: "Open Sans", "Open Sans Placeholder", sans-serif;
    line-height: 1.5em;
    text-align: left;
    color: rgb(0, 20, 30);
    font-size: 18px;
}
.card-show-more-a {
    box-sizing: border-box;
    color: #006699;
    text-decoration: underline;
}

.card-image-wapper-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.card-image-outer-div {
    box-sizing: border-box;

    place-content: center;
    align-items: center;
    aspect-ratio: 1 / 1;
    display: flex;
    flex: 0 0 auto;
    flex-flow: column;
    gap: 10px;
    overflow: hidden;
    padding: 0px;
    position: relative;
    height: 136px;
    width: 136px;

    border-bottom-width: 1px;
    border-color: rgb(204, 208, 210);
    border-left-width: 1px;
    border-right-width: 1px;
    border-style: solid;
    border-top-width: 1px;
    border-radius: 10px;
    transform: none;
    transform-origin: 50% 50% 0px;
    opacity: 1;
}
.card-image-div {
    box-sizing: border-box;

    position: absolute;
    border-radius: inherit;
    inset: 0px;
}
.card-image {
    box-sizing: border-box;

    display: block;
    width: 100%;
    height: 100%;
    border-radius: inherit;
    object-position: center center;
    object-fit: cover;
    image-rendering: auto;
}
