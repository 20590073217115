.legacy-footer-outer-div {
    font-size: 12px;
    font-family: sans-serif;

    box-sizing: border-box;
    flex: 0 0 auto;
    height: auto;
    position: relative;
    width: 100%;
}

.legacy-footer-inner-div {
    font-size: 12px;
    font-family: sans-serif;

    box-sizing: border-box;
    place-content: center;
    align-items: center;
    display: flex;
    flex-flow: column;
    height: auto;
    overflow: hidden;
    position: relative;
    background-color: rgb(0, 40, 61);
    width: 100%;
    opacity: 1;
}

@media only screen and (max-width: 809px) {
    .legacy-footer-inner-div {
        gap: 32px;
        padding: 32px 32px 24px;
    }

    .legacy-footer-grid-div {
        grid-template-columns: repeat(1, minmax(164px, 1fr));
        grid-template-rows: repeat(1, min-content);
    }

    .legacy-footer-logo-div {
        place-content: flex-start;
        align-items: flex-start;
        place-self: start;
        gap: 32px;
    }
}
@media only screen and (min-width: 810px) and (max-width: 1239px) {
    .legacy-footer-inner-div {
        gap: 32px;
        padding: 32px 32px 24px;
    }

    .legacy-footer-grid-div {
        grid-template-columns: repeat(4, minmax(148px, 1fr));
        grid-template-rows: repeat(1, min-content);
    }

    .legacy-footer-logo-div {
        place-self: start;
        place-content: center;
        align-items: center;
        gap: 16px;
        grid-column: 1 / -1;
    }
}

@media only screen and (min-width: 1240px) {
    .legacy-footer-inner-div {
        gap: 64px;
        padding: 64px 64px 24px;
    }

    .legacy-footer-grid-div {
        grid-auto-rows: min-content;
        grid-template-columns: repeat(auto-fill, minmax(164px, 1fr));
    }

    .legacy-footer-logo-div {
        place-content: flex-start;
        align-items: flex-start;
        place-self: start;
        gap: 32px;
    }
}

.legacy-footer-grid-div {
    font-size: 12px;
    font-family: sans-serif;
    box-sizing: border-box;
    display: grid;
    flex: 0 0 auto;
    gap: 48px;
    grid-auto-rows: min-content;
    height: min-content;
    justify-content: center;
    max-width: 1200px;
    overflow: visible;
    padding: 0px;
    position: relative;
    width: 100%;
    opacity: 1;
}

.legacy-footer-logo-div {
    font-size: 12px;
    font-family: sans-serif;
    box-sizing: border-box;
    display: flex;
    flex: 0 0 auto;
    flex-flow: column;
    height: min-content;
    overflow: visible;
    padding: 0px;
    position: relative;
    width: 100%;
    opacity: 1;
}

.legacy-footer-legacy-logo-div {
    font-size: 12px;
    font-family: sans-serif;
    box-sizing: border-box;
    flex: 0 0 auto;
    height: 32px;
    position: relative;
    width: 149px;
    image-rendering: pixelated;
    flex-shrink: 0;
    background-size: 100% 100%;
    background-image: url("../../assets/legacy_footer_logo.svg");
    opacity: 1;
}

.legacy-footer-social-media-logo-div {
    font-size: 12px;
    font-family: sans-serif;
    box-sizing: border-box;
    place-content: center flex-start;
    align-items: center;
    display: flex;
    flex: 0 0 auto;
    flex-flow: row;
    gap: 16px;
    height: min-content;
    overflow: visible;
    padding: 0px;
    position: relative;
    justify-content: flex-end;
    width: min-content;
    opacity: 1;
}

.legacy-footer-sm-logo-div {
    font-size: 12px;
    font-family: sans-serif;
    box-sizing: border-box;
    flex: 0 0 auto;
    height: 40px;
    position: relative;
    width: 40px;
    opacity: 1;
}

.legacy-footer-sm-logo-a {
    font-size: 12px;
    font-family: sans-serif;
    box-sizing: border-box;
    border-width: 0px;
    background: none;
    place-content: center;
    align-items: center;
    cursor: pointer;
    display: flex;
    flex-flow: row;
    gap: 0px;
    overflow: hidden;
    padding: 10px 20px;
    position: relative;
    text-decoration: none;
    border-bottom-width: 0px;
    border-color: rgba(0, 0, 0, 0);
    border-left-width: 0px;
    border-right-width: 0px;
    border-style: solid;
    border-top-width: 0px;
    background-color: rgb(0, 102, 153);
    height: 100%;
    width: 100%;
    opacity: 1;
    border-radius: 100px;
    box-shadow: rgba(255, 255, 255, 0.5) 0px 0.5px 0px 0px inset,
        rgba(0, 0, 0, 0) 0px 0.602187px 0.602187px -1.25px,
        rgba(0, 0, 0, 0) 0px 2.28853px 2.28853px -2.5px,
        rgba(0, 0, 0, 0) 0px 10px 10px -3.75px;
    will-change: auto;
}

.legacy-footer-sm-logo-inner-div {
    font-size: 12px;
    font-family: sans-serif;
    box-sizing: border-box;
    inset: 0px;
    flex: 0 0 auto;
    overflow: hidden;
    position: absolute;
    z-index: 1;
    background: linear-gradient(
        rgba(255, 255, 255, 0.1) 0%,
        rgba(255, 255, 255, 0) 100%
    );
    opacity: 1;
}

.legacy-footer-svg-outer-div {
    font-size: 12px;
    font-family: sans-serif;
    cursor: pointer;
    border-bottom-width: 0px;
    border-color: rgba(0, 0, 0, 0);
    border-left-width: 0px;
    border-right-width: 0px;
    border-style: solid;
    border-top-width: 0px;
    box-sizing: border-box;
    place-content: center flex-start;
    align-items: center;
    display: flex;
    flex: 0 0 auto;
    flex-flow: row;
    gap: 4px;
    height: min-content;
    overflow: visible;
    padding: 0px;
    position: relative;
    width: min-content;
    opacity: 1;
}

.legacy-footer-svg-inner-div {
    font-size: 12px;
    font-family: sans-serif;
    cursor: pointer;
    border-bottom-width: 0px;
    border-color: rgba(0, 0, 0, 0);
    border-left-width: 0px;
    border-right-width: 0px;
    border-style: solid;
    border-top-width: 0px;
    box-sizing: border-box;
    flex: 0 0 auto;
    height: 24px;
    position: relative;
    width: 24px;
    opacity: 1;
}

.legacy-footer-svg-div {
    font-size: 12px;
    font-family: sans-serif;
    cursor: pointer;
    box-sizing: border-box;
    display: contents;
}

.legacy-footer-svg {
    font-size: 12px;
    font-family: sans-serif;
    cursor: pointer;
    color: rgb(255, 255, 255);
    box-sizing: border-box;
    user-select: none;
    width: 100%;
    height: 100%;
    display: inline-block;
    fill: rgb(255, 255, 255);
    flex-shrink: 0;
}

.legacy-footer-links {
    font-size: 12px;
    font-family: sans-serif;
    box-sizing: border-box;
    place-content: flex-start;
    align-items: flex-start;
    place-self: start;
    display: flex;
    flex: 0 0 auto;
    flex-flow: column;
    gap: 16px;
    height: auto;
    overflow: visible;
    padding: 0px;
    position: relative;
    width: 100%;
    opacity: 1;
}

.legacy-footer-links-heading {
    font-size: 12px;
    font-family: sans-serif;
    box-sizing: border-box;
    flex: 0 0 auto;
    height: 40px;
    position: relative;
    white-space: pre-wrap;
    width: 100%;
    word-break: break-word;
    overflow-wrap: break-word;
    outline: none;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-shrink: 0;
    transform: none;
    opacity: 1;
}

.legacy-footer-links-heading-h3 {
    white-space: pre-wrap;
    word-break: break-word;
    overflow-wrap: break-word;
    box-sizing: border-box;
    margin: 0px;
    padding: 0px;
    font-style: normal;
    font-weight: 900;
    font-size: 16px;
    letter-spacing: 0;
    text-transform: none;
    text-decoration: none;
    line-height: 1.2em;
    text-align: start;
    font-feature-settings: initial;
    font-family: sans-serif;
    text-decoration: none;
    text-transform: none;
    color: rgb(199, 176, 137);
}

.legacy-footer-links-item-div {
    font-size: 12px;
    font-family: sans-serif;
    box-sizing: border-box;
    flex: 0 0 auto;
    height: auto;
    position: relative;
    white-space: pre-wrap;
    width: 100%;
    word-break: break-word;
    overflow-wrap: break-word;
    outline: none;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-shrink: 0;
    transform: none;
    opacity: 1;
}

.legacy-footer-links-item-p {
    white-space: pre-wrap;
    word-break: break-word;
    overflow-wrap: break-word;
    box-sizing: border-box;
    margin: 0px;
    padding: 0px;
    font-family: sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 0;
    text-transform: none;
    text-decoration: none;
    line-height: 1.2em;
    text-align: start;
    font-feature-settings: initial;
    color: rgb(255, 255, 255);
}

.legacy-footer-links-item-a {
    white-space: pre-wrap;
    word-break: break-word;
    overflow-wrap: break-word;
    letter-spacing: 0;
    line-height: 1.2em;
    text-align: start;
    font-feature-settings: var(--framer-font-open-type-features, initial);
    box-sizing: border-box;
    font-family: sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    text-transform: none;
    text-decoration: none;
    cursor: pointer;
    color: #ffffff;
}

.legacy-footer-links-item-a:hover {
    text-decoration: underline;
}

.copy-right-outer-div {
    font-size: 12px;
    font-family: sans-serif;
    box-sizing: border-box;
    place-content: center;
    align-items: center;
    display: flex;
    flex: 0 0 auto;
    flex-flow: row;
    gap: 8px;
    height: auto;
    overflow: visible;
    padding: 0px;
    position: relative;
    width: 100%;
    opacity: 1;
}

.copy-right-div {
    font-size: 12px;
    font-family: sans-serif;
    box-sizing: border-box;
    flex: 1 0 0px;
    height: auto;
    position: relative;
    white-space: pre-wrap;
    width: 1px;
    word-break: break-word;
    overflow-wrap: break-word;
    outline: none;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-shrink: 0;
    transform: none;
    opacity: 1;
}

.copy-right-div-p {
    white-space: pre-wrap;
    word-break: break-word;
    overflow-wrap: break-word;
    box-sizing: border-box;
    margin: 0px;
    padding: 0px;
    font-family: sans-serif;
    font-size: 14px;
    line-height: 1.5em;
    text-align: center;
    color: rgba(255, 255, 255, 0.5);
}
